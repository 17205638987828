document.addEventListener('turbolinks:load', () => {
  console.log('scroll top'); 

  var pagetop = $('#pagetop');
  pagetop.hide();
  $(window).on('scroll', function () {
    if ($(this).scrollTop() > 300) {
      pagetop.fadeIn();
    } else {
      pagetop.fadeOut();
    }
  });
  pagetop.on('click', function () {
    $('body, html').animate({ scrollTop: 0 }, 500);
    return false;
  });

// Vueの方式。turbolinks で上手く動かない
// application.html.erb の head部に
//    <script src="https://cdn.jsdelivr.net/npm/vue@2.6.11"></script>
// application.html.erb の body部に
//    <div id="app" v-cloak>
//      <transition>
//        <div id="pagetop" v-show="scY > 300" @click="toTop" v-transition>
//          <i class="fas fa-angle-double-up Page-Btn-Icon"></i>
//        </div>
//      </transition>
//    </div>
//
//  let app = new Vue({
//    el: '#app',
//    data: {
//      scTimer: 0,
//      scY: 0,
//    },
//    created: function(){
//      //scイベント登録
//      window.addEventListener("scroll", this.scEvent);
//      //this.getPostData();
//    },
//    methods: {
//      //トップに戻る
//      toTop: function() {
//        let scrolled = window.pageYOffset;
//        window.scrollTo(0, Math.floor(scrolled * 0.8));
//        if (scrolled > 0) {
//          window.setTimeout(this.toTop, 10);
//        }
//      },
//      //scイベントで現在のスクロール値を取得
//      scEvent: function() {
//        if (this.scTimer) return;
//        this.scTimer = setTimeout(() => {
//          this.scY = window.scrollY;
//          clearTimeout(this.scTimer);
//          this.scTimer = 0;
//        }, 100);
//      }
//    }
//  })
});
