
import 'select2'

// document.addEventListener('turbolinks:load', () => {
window.addEventListener('DOMContentLoaded', () => {
  $('.js-select').select2({
    // placeholder: 'Select an option',
    width: '100%',
    allowClear: true
  })
})
