// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

// Add Custom JS
import '@fortawesome/fontawesome-free/js/all'

import { Foundation } from 'foundation-sites';
import jquery from 'jquery';

window.jQuery = jquery;
window.$ = jquery;

Foundation.addToJquery($);

//jQuery(document).ready($ => ($(document).foundation()));

// Add jQuery UI
import 'jquery-ui-dist/jquery-ui';

// Add Popper JS
import 'popper.js'

// Add Tippy use popper
import tippy from 'tippy.js';
window.tippy = tippy;

// Add Select2
import "packs/select2"

// Add 
//import Clipboard from 'clipboard'

////require('jquery/src/jquery')
//require('foundation-sites')
//require('jquery')
//import "foundation-sites"

//$(document).on('turbolinks:load', function() {
//  $(document).foundation()
//});

document.addEventListener('turbolinks:load', () => {
  $(document).foundation()
})


//require('packs/maps/map_shop')
require('packs/scrolltop')

Rails.start()
Turbolinks.start()
ActiveStorage.start()


import I18n from 'i18n-js'
window.I18n = I18n

